import {
    ADD_NEW_THOUGHT, 
    FETCH_THOUGHTS,
    UPDATE_THOUGHTS
} from '../actions/types';
  
  export function thoughtReducer(state = { all: {} }, action) {
    switch (action.type) {
      case ADD_NEW_THOUGHT:
        return { ...state };
      case FETCH_THOUGHTS: 
        return { ...state, all: action.payload };
      case UPDATE_THOUGHTS: 
        return { ...state, all: { ...state.all, ...action.payload } };
      default:
        return state;
    }
  }