import {
    ADD_NEW_THOUGHT,
    FETCH_THOUGHTS,
    UPDATE_THOUGHTS
} from '../actions/types';
import { db } from '../utils/firebase';

/**
 * Adds a new thought to the firebase db 
 *
 * @export
 * @param { thought, date, likeCount: 0, forkCount: 0 } data
 * @returns
 */
export function addNewThought(data) {
    let request = db.collection("thoughts").add(data) 
                    .then(function(docRef) {
                        console.log("Document written with ID: ", docRef.id);
                        return Promise.resolve({ docRef: docRef.id })
                    })
                    .catch(function(error) {
                        console.error("Error adding document: ", error);
                        return Promise.resolve(error)
                    });
    return {
        type: ADD_NEW_THOUGHT,
        payload: request
    }
}


/**
 *  Returns all the data in the db collection named thoughts
 *
 * @export
 * @param { } data
 * @returns
 */
export function fetchThoughts() {
    let request = db.collection("thoughts").orderBy("date", "desc").get().then(querySnapshot => {
        let returnSnap = {};
        querySnapshot.forEach((doc) => {
            // console.log('s', doc.id, doc.data())
            returnSnap[doc.id] = doc.data();
        });        
        return returnSnap;
    })
    return {
        type: FETCH_THOUGHTS,
        payload: request
    }
}

/**
 * Update Thought - Generic function to update likeCount / thought itself
 *
 * @export
 * @param { id, newData } data --> where id is the thoughtId
 * @returns
 */
export function updateThoughts({ id, newData }) {
    let request = db.collection(`thoughts`).doc(id).update({...newData, liked: null}) // make sure liked property gets removed from db
                  .then(() => { 
                    return Promise.resolve({ [id]: newData })
                  }).catch(function(error) {
                    console.error("Error adding document: ", error);
                    return Promise.resolve(error)
                  });;
    return {
        type: UPDATE_THOUGHTS,
        payload: request
    }
}