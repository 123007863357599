import React, { useState } from 'react';
import Nav from '../components/Nav';
import { withRouter } from 'react-router-dom';
import '../css/add.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addNewThought } from '../actions/thoughtActions';

let AddThought = ({ history, toggleDarkMode, addNewThought }) => {
    const [message, updateMessage] = useState("");

    const localAddNewThought = () => {
        if(message !== "" && message) {
            console.log('add local thought')
            return addNewThought({
                thought: message, 
                date: new Date(),
                likeCount: 1, 
                forkCount: 0
            }).then(() => {
                return updateMessage('')
            })
        }
    }
    return (
        <>
            <div>
                <button className="back" onClick={() => history.push('/')}>
                    <i className="fa fa-chevron-left"></i> <span> Back to Thoughts</span>
                </button>
                <form>
                    <textarea value={message} onChange={(e) => updateMessage(e.target.value)} className="textarea" name="thought" placeholder="Start Typing Here ... " />
                </form>
            </div>
            <Nav toggleDarkMode={toggleDarkMode} actionText="Publish" action={localAddNewThought} />
        </>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        addNewThought
    }, dispatch);
}

function mapStateToProps(state) {
    return { 

    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddThought));