import React from 'react';
import { Link } from 'react-router-dom';

let NotFound404 = () => {
    return (
        <div className="center" style={{ padding: '2em' }} >
            <h1 style={{fontSize: '5em'}}>404</h1>
            <h2 style={{fontSize: '2em'}}>Not Found</h2>
            <p>The page you were looking for was not found. <Link to="/">Click here to go back to the thought board.</Link></p>
        </div>
    )
}

export default NotFound404