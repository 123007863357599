import React from 'react';
import Modal from 'react-modal';
import '../../css/modal.css'

let ModalContainer = ({ open, onClose, children, darkMode }) => {
  const customStyles = {
    overlay: {
      backgroundColor       : darkMode ? 'rgba(32,32,42,0.9)' : 'rgba(250,252,255,0.9)'
    },
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      border                : 'none',
      borderRadius          : '12px',
      boxShadow             : `0 12px 40px ${darkMode ? '#000000' : '#EBEBEB'}`,
      minWidth              : '260px',
      width                 : '40%',
      maxWidth              : '500px',
      padding               : '30px',
      backgroundColor       : darkMode ? 'var(--dark-background)' : 'var(--light-background)',
      color                 : darkMode ? 'var(--dark-text)' : 'var(--light-text)'
    }
  };
  
  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      style={customStyles}
    >
      <button style={{ background: 'none', opacity: 0.8, fontSize: '1em', float: 'right' }} onClick={onClose}>x</button>
      {children}
    </Modal>
  )
}

export default ModalContainer