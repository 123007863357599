import React, { useState } from 'react';
import '../css/social.css';
import Modal from './Modals/_Modal';
import SocialShare from './SocialShare';

let SocialBlock = ({ history, liked, likeCount, forkCount, updateLikeCount, message, darkMode }) => {
    const [open, toggleModal] = useState(false);
    return (
        <div className="social">
            <span className="social__single">
                <button onClick={updateLikeCount}><i className={`${liked ? 'fas' : 'far'} fa-thumbs-up`}></i><span className="social__single-label"> {likeCount}</span></button>
            </span>
            {/* #thoughtboards */}
            {/* <span className="social__single">
                <button><i className="fas fa-code-branch"></i><span className="social__single-label"> {forkCount}</span></button>
            </span> */}
            {/* <span className="social__single">
                <button><i className="far fa-heart"></i><span className="social__single-label"> Save</span></button>
            </span> */}
            <span className="social__single">
                <button onClick={() => toggleModal(!open)}><i className="fas fa-share"></i><span className="social__single-label"> Share</span></button>
                {open && 
                    <Modal open={open} onClose={() => toggleModal(false)} darkMode={darkMode}>
                        <p>Share Thought</p>
                        <p style={{ fontSize: '1.2em', opacity: '0.6' }}>{message}</p>
                        <SocialShare message={message} />
                    </Modal>
                }
            </span>
        </div>
    )
}

export default SocialBlock