import React from 'react';
import '../css/nav.css';
import { allowEdits } from '../utils/firebase';

let Nav = ({ toggleDarkMode, action, actionText }) => {
    return (
        <div className="right-section">
            { allowEdits && <button onClick={action} className="btn__new">
                <span><i className="fa fa-plus"></i></span>
                <span>{actionText}</span>
            </button>}
            <nav>
                <ul>
                    <li id="account"><a href="https://radhasatam.com"><i className="far fa-user"></i> <span className="nav__description">PORTFOLIO</span></a></li>
                    <li id="linkedin"><a href="https://www.linkedin.com/in/radhasatam/"><i className="fab fa-linkedin"></i> <span className="nav__description">LINKEDIN</span></a></li>
                    <li id="github"><a href="https://github.com/RadhaSatam"><i className="fab fa-github"></i> <span className="nav__description">GITHUB</span></a></li>
                    <li id="twitter"><a href="https://twitter.com/radhasatam"><i className="fab fa-twitter"></i> <span className="nav__description">TWITTER</span></a></li>
                    <li id="behance"><a href="https://www.behance.net/radhasatam"><i className="fab fa-behance"></i> <span className="nav__description">BEHANCE</span></a></li>
                    {/* <li id="fork"><i className="fas fa-code-branch"></i> <span className="nav__description">MY FORKS</span></li> */}
                    {/* <li id="saved"><i className="far fa-heart"></i> <span className="nav__description">SAVED</span></li> */}
                    <li id="toggle"><button onClick={toggleDarkMode}><i className="far fa-moon"></i>  <span className="nav__description">TOGGLE DARK MODE</span></button></li>
                </ul>
            </nav>
        </div>
    )
}

export default Nav