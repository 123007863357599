import React from 'react';
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import Home from '../pages/Home';
import AddThought from '../pages/AddThought';
import NotFound404 from '../pages/NotFound404';
import { allowEdits } from '../utils/firebase';

let Main = ({ children }) => (
    <div id="main">
        {children}
    </div>
)

let Body = (props) => (
    <Router>
        <Switch>
            <Route exact path="/" component={() => <Main><Home {...props} /></Main>} />
            {
                allowEdits && <Route path="/add" component={() => <Main><AddThought {...props} /></Main>} /> 
            }
            <Route path="*" component={NotFound404} />
        </Switch>
    </Router>
)   

export default Body