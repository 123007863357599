import React, { useEffect } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchThoughts, updateThoughts } from '../actions/thoughtActions';

import SocialBlock from '../components/SocialBlock';
import Nav from '../components/Nav';

let Home = ({ thoughts, history, darkMode, toggleDarkMode, fetchThoughts, updateThoughts } ) => {
    // const [thoughts, updateThoughts] = useState(thoughtsData);

    let updateLikeCount = ({ tKey }) => {
        let newData = { ...thoughts[tKey],                             
                        liked: !thoughts[tKey].liked, // has this been liked, if so, set it to false, else set to true
                        likeCount: !thoughts[tKey].liked ? thoughts[tKey].likeCount + 1 : thoughts[tKey].likeCount - 1 
                      };
        return updateThoughts({ id: tKey, newData })
    }

    useEffect(() => {
        fetchThoughts()
    },[])

    return (
        <>
        <div id="left-section">
            {
                thoughts && Object.keys(thoughts).length > 0 &&
                Object.keys(thoughts).map((tKey, id) => {
                    let thought = thoughts[tKey];
                    return (
                    <div key={tKey} className="thought-container">
                        <p className="date">{moment(thought.date.seconds * 1000).format('MMMM Do YYYY, h:mm a')}</p>
                        <p className="thought">{thought.thought}</p>
                        <SocialBlock liked={thought.liked}
                                     likeCount={thought.likeCount} 
                                     updateLikeCount={() => updateLikeCount({ tKey })} 
                                     forkCount={thought.forkCount}
                                     darkMode={darkMode}
                                     message={thought.thought}
                        />
                    </div>
                    )}
                )
            }
        </div>
        <Nav toggleDarkMode={toggleDarkMode} 
             action={() => history.push('/add')} 
             actionText="New Thought"
        />
      </>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
        fetchThoughts,
        updateThoughts
    }, dispatch);
}

function mapStateToProps(state) {
    return { 
        thoughts: state.thoughts && state.thoughts.all && Object.keys(state.thoughts.all).length > 0 ? state.thoughts.all : null
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));