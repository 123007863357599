import { createStore, applyMiddleware, compose } from 'redux';
import ReduxPromise from 'redux-promise';
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";
import { configType } from './utils/firebase';

const middleware = [thunk, ReduxPromise];

const composeEnhancers = configType === 'dev' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(...middleware)
));

export default store;