import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database'; // If using Firebase database

var firebaseConfig = {
    apiKey: "AIzaSyDUk2G-EcfQvWUdIir4bIkLMWhPKAmzMv0",
    authDomain: "personal-thought-board.firebaseapp.com",
    databaseURL: "https://personal-thought-board.firebaseio.com",
    projectId: "personal-thought-board",
    storageBucket: "personal-thought-board.appspot.com",
    messagingSenderId: "477706934249",
    appId: "1:477706934249:web:2e3b6c3e72f500a4"
};

export const configType = 'dev';
// export const configType = 'live';

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();

export const allowEdits = false;
// CHANGE THIS TO ALLOW ADDING NEW THOUGHTS - JUST FOR YOU.