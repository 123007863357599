import React, { useState } from 'react';
// import Masonry from './components/Masonry';
import Body from './layout/Body';
import Footer from './layout/Footer';


function App() {
  const [darkMode, toggleDarkMode] = useState(false);
  return (
    <div className={`App ${darkMode ? 'dark' : 'light'}-theme`}>
      {/* <Masonry /> */}
      <div className={`container ${darkMode ? 'dark' : 'light'}-theme`}>
        <Body darkMode={darkMode} toggleDarkMode={() => toggleDarkMode(!darkMode)} />
        <Footer />
      </div>
    </div>
  );
}

export default App;
