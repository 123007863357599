import React from 'react';
import { Twitter, Telegram, Linkedin, Whatsapp } from 'react-social-sharing'
import '../css/social-share.css'

let SocialShare = ({ message }) => {
    message = '"' + message + '"'
    let link = "https://thoughts.radhasatam.com";
    let linkLength = link.length;
    let twitterMessage = message.length > 280 - linkLength ?  message.substr(0, 280 - linkLength - 3) + '...' : message;

    return (
        <div className="social-share">
            <Twitter solid small message={twitterMessage} link={link} />
            <Linkedin solid small message={message} link={link}/>
            <Telegram solid small message={message} link={link}/>
            <Whatsapp solid small message={message} link={link}/>
        </div>
    )
}

export default SocialShare